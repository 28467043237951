import { AdminConfig } from '@meemoo/admin-core-ui/dist/react-admin/core/config/config.types';

export const ROUTE_PARTS = Object.freeze({
	about: 'over-bezoekersruimtes',
	accessRequested: 'toegang-aangevraagd',
	account: 'account',
	visitRequests: 'toegangsaanvragen',
	materialRequests: 'materiaalaanvragen',
	myMaterialRequests: 'mijn-materiaalaanvragen',
	faq: 'faq',
	userPolicy: 'gebruiksvoorwaarden',
	cookiebeleid: 'cookiebeleid',
	userManagement: 'gebruikersbeheer',
	user: 'gebruikers',
	permissions: 'permissies',
	translations: 'vertalingen',
	admin: 'admin',
	beheer: 'beheer',
	content: 'content',
	contentPageLabels: 'content-pagina-labels',
	cookiePolicy: 'cookiebeleid',
	create: 'maak',
	edit: 'bewerk',
	logout: 'uitloggen',
	myFolders: 'mijn-mappen',
	myHistory: 'mijn-bezoek-historiek',
	myProfile: 'mijn-profiel',
	navigation: 'navigatie',
	notFound: '404',
	settings: 'instellingen',
	users: 'gebruikers',
	visitRequest: 'aanvraag',
	visitorSpaceManagement: 'bezoekersruimtesbeheer',
	visitorSpaces: 'bezoekersruimtes',
	activeVisitors: 'actieve-bezoekers',
	search: 'zoeken',
	visitors: 'bezoekers',
	visit: 'bezoek',
	alerts: 'meldingen',
	shareFolder: 'map-delen',
	kioskConditions: 'kiosk-voorwaarden',
});

// Note: Also used to set 'Bezoekersruimtes' active state if url does not start with any of the following prefixes
export const ROUTE_PREFIXES = Object.freeze({
	beheer: ROUTE_PARTS.beheer,
	admin: ROUTE_PARTS.admin,
	account: ROUTE_PARTS.account,
	about: ROUTE_PARTS.about,
	faq: ROUTE_PARTS.faq,
	gebruiksvoorwaarden: ROUTE_PARTS.userPolicy,
	cookiebeleid: ROUTE_PARTS.cookiePolicy,
	notFound: ROUTE_PARTS.notFound,
});

export const ROUTES = Object.freeze({
	home: '/',
	search: `/${ROUTE_PARTS.search}`,
	bezoek: `/${ROUTE_PARTS.visit}`,
	space: `/${ROUTE_PARTS.search}/:slug`,
	termsOfService: '/' + ROUTE_PARTS.userPolicy,
	cookiePolicy: '/' + ROUTE_PARTS.cookiePolicy,
	myFolders: `/${ROUTE_PARTS.account}/${ROUTE_PARTS.myFolders}`,
	myHistory: `/${ROUTE_PARTS.account}/${ROUTE_PARTS.myHistory}`,
	visitRequested: `/${ROUTE_PARTS.visit}/:slug/${ROUTE_PARTS.accessRequested}`,
	adminEditSpace: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.visitorSpaceManagement}/${ROUTE_PARTS.visitorSpaces}/:slug`,
	beheerVisitors: `/${ROUTE_PARTS.beheer}/${ROUTE_PARTS.visitors}`,
	beheerSettings: `/${ROUTE_PARTS.beheer}/${ROUTE_PARTS.settings}`,
	beheerRequests: `/${ROUTE_PARTS.beheer}/${ROUTE_PARTS.visitRequests}`,
	beheerMaterialRequests: `/${ROUTE_PARTS.beheer}/${ROUTE_PARTS.materialRequests}`,
	shareFolder: `/${ROUTE_PARTS.account}/${ROUTE_PARTS.shareFolder}/:id`,
});

export const ADMIN_CORE_ROUTES: AdminConfig['routes'] = {
	ADMIN_ALERTS_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.alerts}`,
	ADMIN_CONTENT_PAGE_CREATE: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.content}/${ROUTE_PARTS.create}`,
	ADMIN_CONTENT_PAGE_DETAIL: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.content}/:id`,
	ADMIN_CONTENT_PAGE_EDIT: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.content}/:id/${ROUTE_PARTS.edit}`,
	ADMIN_CONTENT_PAGE_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.content}`,
	ADMIN_CONTENT_PAGE_LABEL_CREATE: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.contentPageLabels}/${ROUTE_PARTS.create}`,
	ADMIN_CONTENT_PAGE_LABEL_DETAIL: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.contentPageLabels}/:id`,
	ADMIN_CONTENT_PAGE_LABEL_EDIT: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.contentPageLabels}/:id/${ROUTE_PARTS.edit}`,
	ADMIN_CONTENT_PAGE_LABEL_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.contentPageLabels}`,
	ADMIN_NAVIGATION_CREATE: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.navigation}/${ROUTE_PARTS.create}`,
	ADMIN_NAVIGATION_DETAIL: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.navigation}/:navigationBarId`,
	ADMIN_NAVIGATION_ITEM_CREATE: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.navigation}/:navigationBarId/${ROUTE_PARTS.create}`,
	ADMIN_NAVIGATION_ITEM_EDIT: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.navigation}/:navigationBarId/:navigationItemId/${ROUTE_PARTS.edit}`,
	ADMIN_NAVIGATION_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.navigation}`,
	ADMIN_TRANSLATIONS_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.translations}`,
	ADMIN_USER_DETAIL: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.users}/:id`,
	ADMIN_USER_EDIT: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.users}/:id/${ROUTE_PARTS.edit}`,
	ADMIN_USER_GROUP_CREATE: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.permissions}/${ROUTE_PARTS.create}`,
	ADMIN_USER_GROUP_DETAIL: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.permissions}/:id`,
	ADMIN_USER_GROUP_EDIT: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.permissions}/:id/${ROUTE_PARTS.edit}`,
	ADMIN_USER_GROUP_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.permissions}`,
	ADMIN_USER_OVERVIEW: `/${ROUTE_PARTS.admin}/${ROUTE_PARTS.users}`,
	SEARCH: `/${ROUTE_PARTS.search}`,
};

export enum KNOWN_STATIC_ROUTES {
	TermsOfService = '/gebruikersvoorwaarden-tekst',
	Home = '',
}
