interface Language {
	[languageCode: string]: string;
}

interface LanguagesByLanguage {
	[languageCode: string]: Language;
}

export const LANGUAGES: LanguagesByLanguage = {
	nl: {
		aa: 'Afar',
		ab: 'Abchazisch',
		ae: 'Avestisch',
		af: 'Afrikaans',
		ak: 'Akan',
		am: 'Amhaars',
		an: 'Aragonees',
		ar: 'Arabisch',
		as: 'Assamees',
		av: 'Avaars',
		ay: 'Aymara',
		az: 'Azerbeidzjaans',
		ba: 'Basjkiers',
		be: 'Wit-Russisch',
		bg: 'Bulgaars',
		bh: 'Bihari',
		bi: 'Bislama',
		bm: 'Bambara',
		bn: 'Bengaals',
		bo: 'Tibetaans',
		br: 'Bretons',
		bs: 'Bosnisch',
		ca: 'Catalaans',
		ce: 'Tsjetsjeens',
		ch: 'Chamorro',
		co: 'Corsicaans',
		cr: 'Cree',
		cs: 'Tsjechisch',
		cu: 'Kerkslavisch',
		cv: 'Tsjoevasjisch',
		cy: 'Welsh',
		da: 'Deens',
		de: 'Duits',
		dv: 'Divehi',
		dz: 'Dzongkha',
		ee: 'Ewe',
		el: 'Grieks',
		en: 'Engels',
		eo: 'Esperanto',
		es: 'Spaans',
		et: 'Estisch',
		eu: 'Baskisch',
		fa: 'Perzisch',
		ff: 'Fula',
		fi: 'Fins',
		fj: 'Fijisch',
		fo: 'Faerers',
		fr: 'Frans',
		fy: 'Westerlauwers Fries',
		ga: 'Iers',
		gd: 'Schots-Gaelisch',
		gl: 'Galicisch',
		gn: 'Guaran',
		gu: 'Gujarati',
		gv: 'Manx-Gaelisch',
		ha: 'Hausa',
		he: 'Hebreeuws',
		hi: 'Hindi',
		ho: 'Hiri Motu',
		hr: 'Kroatisch',
		ht: 'Haitiaans Creools',
		hu: 'Hongaars',
		hy: 'Armeens',
		hz: 'Herero',
		ia: 'Interlingua',
		id: 'Indonesisch',
		ie: 'Interlingue',
		ig: 'Igbo',
		ii: 'Yi',
		ik: 'Inupiak',
		io: 'Ido',
		is: 'IJslands',
		it: 'Italiaans',
		iu: 'Inuktitut',
		ja: 'Japans',
		jv: 'Javaans',
		ka: 'Georgisch',
		kg: 'Kongo',
		ki: 'Gikuyu',
		kj: 'Kwanyama',
		kk: 'Kazachs',
		kl: 'Groenlands',
		km: 'Cambodjaans',
		kn: 'Kannada',
		ko: 'Koreaans',
		kr: 'Kanuri',
		ks: 'Kasjmiri',
		ku: 'Koerdisch',
		kv: 'Zurjeens',
		kw: 'Cornisch',
		ky: 'Kirgizisch',
		la: 'Latijn',
		lb: 'Luxemburgs',
		lg: 'Luganda',
		li: 'Limburgs',
		ln: 'Lingala',
		lo: 'Laotiaans',
		lt: 'Litouws',
		lu: 'Luba-Katanga',
		lv: 'Lets',
		mg: 'Plateaumalagasi',
		mh: 'Marshallees',
		mi: 'Maori',
		mk: 'Macedonisch',
		ml: 'Malayalam',
		mn: 'Mongools',
		mo: 'Moldavisch',
		mr: 'Marathi',
		ms: 'Maleis',
		mt: 'Maltees',
		my: 'Birmaans',
		na: 'Nauruaans',
		nb: 'Bokml-Noors',
		nd: 'Noord-Ndebele',
		ne: 'Nepalees',
		ng: 'Ndonga',
		nl: 'Nederlands',
		nn: 'Nynorsk',
		no: 'Noors',
		nr: 'Zuid-Ndebele',
		nv: 'Navajo',
		ny: 'Nyanja',
		oc: 'Occitaans',
		oj: 'Ojibweg',
		om: 'Afaan Oromo',
		or: 'Odia',
		os: 'Ossetisch',
		pa: 'Punjabi',
		pi: 'Pali',
		pl: 'Pools',
		ps: 'Pasjtoe',
		pt: 'Portugees',
		qu: 'Quechua',
		rm: 'Reto-Romaans',
		rn: 'Kirundi',
		ro: 'Roemeens',
		ru: 'Russisch',
		rw: 'Kinyarwanda',
		sa: 'Sanskriet',
		sc: 'Sardijns',
		sd: 'Sindhi',
		se: 'Noord-Samisch',
		sg: 'Sango',
		sh: 'Servo-Kroatisch',
		si: 'Singalees',
		sk: 'Slowaaks',
		sl: 'Sloveens',
		sm: 'Samoaans',
		sn: 'Shona',
		so: 'Somalisch',
		sq: 'Albanees',
		sr: 'Servisch',
		ss: 'Swazi',
		st: 'Zuid-Sotho',
		su: 'Soendanees',
		sv: 'Zweeds',
		sw: 'Swahili',
		ta: 'Tamil',
		te: 'Telugu',
		tg: 'Tadzjieks',
		th: 'Thai',
		ti: 'Tigrinya',
		tk: 'Turkmeens',
		tl: 'Tagalog',
		tn: 'Tswana',
		to: 'Tongaans',
		tr: 'Turks',
		ts: 'Tsonga',
		tt: 'Tataars',
		tw: 'Twi',
		ty: 'Tahitiaans',
		ug: 'Oeigoers',
		uk: 'Oekraïens',
		ur: 'Urdu',
		uz: 'Oezbeeks',
		ve: 'Venda',
		vi: 'Vietnamees',
		vo: 'Volapk',
		wa: 'Waals',
		wo: 'Wolof',
		xh: 'Xhosa',
		yi: 'Jiddisch',
		yo: 'Yoruba',
		za: 'Zhuang',
		zh: 'Chinees',
		zu: 'Zoeloe',
	},
};
